/* DARK MODE */

.darkmode--activated p,
.darkmode--activated a,
.darkmode--activated h1,
.darkmode--activated h2,
.darkmode--activated h3,
.darkmode--activated h4,
.darkmode--activated h5,
.darkmode--activated h3,
.darkmode--activated small,
.darkmode--activated label,
.darkmode--activated span,
.darkmode--activated th,
.darkmode--activated td,
.darkmode--activated input,
.darkmode--activated textarea,
.darkmode--activated span,
.darkmode--activated .select-search,
.darkmode--activated .tab,
.darkmode--activated .react-tagsinput-tag,
.darkmode--activated .react-joyride__tooltip strong,
.darkmode--activated .css-1uccc91-singleValue,
.darkmode--activated .react-confirm-alert-body,
.darkmode--activated {
  color: rgb(255, 244, 244) !important;
}

.darkmode--activated .text-success {
  color: #28a745 !important;
}
.darkmode--activated .text-danger {
  color: #dc3545 !important;
}
.darkmode--activated .text-info {
  color: #17a2b8 !important;
}

.darkmode--activated .card-relatorio,
.darkmode--activated .card-content,
.darkmode--activated .container#header,
.darkmode--activated .react-joyride__tooltip,
.darkmode--activated .react-responsive-modal-modal,
.darkmode--activated .css26l3qy-menu,
.darkmode--activated .space-final,
.darkmode--activated .react-confirm-alert-body {
  background-color: #202224 !important;
}

.darkmode--activated .card-campanha-agendada,
.darkmode--activated .card-campanha-finalizada-success,
.darkmode--activated .page-link,
.darkmode--activated input,
.darkmode--activated textarea,
.darkmode--activated .css-yk16xz-control,
.darkmode--activated .css-1pahdxg-control,
.darkmode--activated .react-tagsinput,
.darkmode--activated .input-group-text,
.darkmode--activated .input-group-append,
.darkmode--activated .select-search,
.darkmode--activated .list,
.darkmode--activated #home .card-mensagem {
  background-color: #353a3f !important;
}

.darkmode--activated .react-tagsinput,
.darkmode--activated .input-group-text,
.darkmode--activated .input-form {
  border: 1px solid rgb(85, 82, 82) !important;
}

.darkmode--activated .css-1pahdxg-control {
  border: 1px solid var(--cor-principal) !important;
  box-shadow: 0 0 0 2px var(--cor-principal) !important;
}

.darkmode--activated .css-yk16xz-control:active,
.darkmode--activated .css-1pahdxg-control:active,
.darkmode--activated .css-1pahdxg-control:hover,
.darkmode--activated textarea,
.darkmode--activated .css-yk16xz-control {
  border: 1px solid var(--cor-principal) !important;
}

.darkmode--activated .css-6q0nyr-Svg {
  color: rgb(255, 244, 244) !important;
  fill: var(--cor-principal) !important;
}

.darkmode--activated .css-1wa3eu0-placeholder {
  color: rgb(255, 244, 244) !important;
}

.darkmode--activated .react-joyride__tooltip path {
  background-color: aliceblue !important;
}

.darkmode--activated .css-2b097c-container {
  color: #ffffff !important;
}

.darkmode--activated .react-joyride__overlay {
  background-color: rgba(26, 27, 27, 0.801) !important;
}

.darkmode-toggle {
  z-index: 999;
}

.darkmode--activated .css-1rhbuit-multiValue {
  background-color: #111518 !important;
  color: aliceblue !important;
}

.darkmode--activated .css-12jo7m5 {
  color: aliceblue;
}

.darkmode--activated .css-2b097c-container > div {
  background-color: #353a3f !important;
  border-color: var(--cor-principal) !important;
  color: aliceblue;
}

.darkmode--activated .css-2b097c-container div div > div:hover {
  background-color: #1f4870 !important;
}

.darkmode--activated .error {
  border-color: red !important;
}

.darkmode--activated .menu-item:hover,
.darkmode--activated .menu-item_secondary:hover {
  background-color: #353a3f !important;
}

.darkmode--activated #campanhas button {
  background-color: #4e5761;
  color: aliceblue;
}

.darkmode--activated #campanhas button:hover {
  background-color: #77797c;
}

.darkmode--activated .styledLogo,
.darkmode--activated .authDisplayLogoPreta {
  display: none;
}

.darkmode--activated .styledLogoBranco,
.darkmode--activated .authDisplayLogoBranca {
  display: inline !important;
}

/* CHAT */

.darkmode--activated #chat {
  display: inline;
  background-color: #000000 !important;
}

.darkmode--activated #chat .card-chat .body {
  background-image: url("../assets/imgs/wallpaperDark.png") !important;
}

.darkmode--activated #chat .header {
  background-color: #232627 !important;
  border-bottom: #000000 !important;
}

.darkmode--activated #chat .footer {
  background-color: #1e2428;
}

.darkmode--activated #chat .filter,
.darkmode--activated #chat .list-chats {
  background-color: #232627;
}

.darkmode--activated #chat .chat {
  background-color: #0f161a;
}

.darkmode--activated #chat .chat:hover {
  background-color: rgb(40, 44, 45);
}

.darkmode--activated ::-webkit-scrollbar-thumb {
  background: #4d5a58;
}

.darkmode--activated ::-webkit-scrollbar-track {
  background: #000000 !important;
}

.darkmode--activated #chat .list-chats {
  border: none !important;
}

.darkmode--activated .card-me {
  background-color: #396669 !important;
}

.darkmode--activated .card-he {
  background-color: #32333c !important;
}

.darkmode--activated #chat .card-chat {
  border: none;
}

.darkmode--activated .visualizar,
.darkmode--activated .detalhes {
  color: var(--cor-principal) !important;
}

.darkmode--activated #campanhas .sucess-file p {
  color: #5cb85c !important;
}

.darkmode--activated #campanhas .agendada {
  color: #1544bd !important;
}
.darkmode--activated #campanhas .naoProntaEnviar {
  color: #f1cb01 !important;
}

.darkmode--activated #campanhas .finalizada-success {
  color: #00c543 !important;
}

.darkmode--activated #campanhas .finalizada-error {
  color: #dd0000 !important;
}

.darkmode--activated .select-search {
  background-color: #080903 !important;
}

.darkmode--activated .help {
  background-color: #008080;
}

.darkmode--activated .react-confirm-alert-overlay {
  background-color: #0000008a;
}

.darkmode--activated input::placeholder,
.darkmode--activated textarea::placeholder {
  color: #ffffff60;
}

.darkmode--activated .arrasteAqui svg path,
.darkmode--activated .react-joyride__tooltip button svg,
.darkmode--activated .react-responsive-modal-closeButton svg {
  stroke: white;
  fill: white;
}

.darkmode--activated #modal-qrcode {
  background-color: #ffffff;
  color: #202224;
}

.darkmode--activated .text-qrcode {
  color: #202224 !important;
}
#header {
  background: var(--cor-theme-cards);
  box-shadow: -5px 0px 40px var(--cor-sombreado);
  position: -webkit-sticky;
}
.headerFilho {
  height: 42rem !important;
}

.nameAccount {
  word-break: break-all;
}

#header .logo {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}

@media (max-width: 991px) {
  #header {
    height: 100%;
  }
  #header .logo {
    margin-top: 5px;
  }
}

#header .body_area {
  padding-top: 20px;
  padding-bottom: 100px;
}

#header .select-menu {
  padding: 10px;
  margin-left: 10px;
  margin-right: 7px;
}

#header .css-yk16xz-control,
.css-26l3qy-menu,
.css-1pahdxg-control {
  border: 1px solid var(--cor-principal-escura) !important;
  box-shadow: 0 0 0 1px var(--cor-principal-escura) !important;
}

#header .css-yk16xz-control,
.css-1pahdxg-control {
  min-height: 46px !important;
}

#header .css-1pahdxg-control {
  border: 1px solid var(--cor-principal) !important;
  box-shadow: 0 0 0 2px var(--cor-principal) !important;
}

#header .css-yk16xz-control:active,
.css-1pahdxg-control:active,
.css-1pahdxg-control:hover {
  border: 1px solid var(--cor-principal) !important;
}

#header .css-6q0nyr-Svg {
  fill: var(--cor-principal) !important;
}

#header .css-1wa3eu0-placeholder {
  font-family: "Lato-Regular";
  color: var(--cor-principal-escura);
}

#header .body_area .menu-item {
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 20px;
  margin-left: 20px;
  margin-right: 15px;
  height: 60px;
  font-family: "Lato-Regular";
  font-weight: 400;
  font-size: 16px;
  color: rgba(110, 116, 135, 0.8);
  text-decoration: none;
}

#header .body_area .menu-item_secondary {
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 20px;
  margin-left: 20px;
  margin-right: 15px;
  height: 66px;
  font-family: "Lato-Regular";
  font-weight: 600;
  font-size: 16px;
  color: #b2b2b2;
  text-decoration: none;
}

#header .icon-menu {
  color: var(--cor-principal-escura);
  z-index: 1;
  position: absolute;
  top: 15px;
  left: 10px;
}

#header .body_area .active {
  background-color: var(--cor-principal-minopacity);
  color: var(--cor-principal-escura);
}

#header .styledLogo {
  height: 80px;
}

#header .styledLogoBranco {
  height: 80px;
  display: none;
}

@media (max-width: 1270px) and (min-width: 768px) {
  #header .body_area .menu-item,
  #header .body_area .menu-item_secondary {
    padding-left: 6px;
  }
  .select-menu {
    padding: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
}

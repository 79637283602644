#campanhas .agendada {
  font-family: "Lato-Regular";
  color: #1544bd;
}
#campanhas .naoProntaEnviar {
  font-family: "Lato-Regular";
  color: #f1cb01;
}

#campanhas .finalizada-success {
  font-family: "Lato-Regular";
  color: #00c543;
}

#campanhas .finalizada-error {
  font-family: "Lato-Regular";
  color: #dd0000;
}

#campanhas .icon {
  position: relative;
  top: 4px;
}

#campanhas .icon-close {
  color: #1544bd;
}

#campanhas .list {
  background: #f7f7fa;
  border-radius: 8px 8px 0px 0px;
  background-color: var(--cor-theme);
  padding: 20px;
  height: 500px;
  overflow: auto;
}

#campanhas .label-arquivo {
  font-family: "Lato-Regular";
  color: #00c543;
}

#campanhas .space-final {
  padding: 20px;
  background: #e9eaf4;
  border-radius: 0px 0px 8px 8px;
}


#campanhas .AiOutlineCloudDownload {
  top: 4px;
}

#modalStatus table {
  overflow-y: scroll;
}

@media (min-width: 768px) {
  .tagsresponsive {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@font-face {
    font-family: 'Montserrat-Regular';
    src: url('Montserrat-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Thin';
    src: url('Montserrat-Thin.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url('Montserrat-SemiBold.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: url('Montserrat-Bold.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Medium';
    src: url('Montserrat-Medium.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato-Regular';
    src: url('Lato-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato-Bold';
    src: url('Lato-Bold.ttf');
    font-weight: normal;
    font-style: normal;
}
#chat {
    background-color: rgb(60, 151, 136) !important;
    height: 100vh;
  }

  #chat .chat-focus {
    background-color: rgba(204, 204, 204, 0.2);
  }
  
  #chat .list-chats {
    background-color: #ffff;
    width: 100%;
    height: 95vh;
    overflow: auto;
    border: solid 1px rgba(204, 204, 204, 0.2);
  }
  
  #chat .list-chats .filter {
    background-color: #f0f0f0;
    padding: 15px;
    border-bottom: solid 1px rgba(204, 204, 204, 0.2);
    border-right: solid 1px rgba(204, 204, 204, 0.2);
  }
  
  #chat .list-chats .input_filter {
    background-color: #fff;
    border-radius: 15px;
    border: none;
    width: 100%;
    padding-left: 15px;
    font-family: "Montserrat-Regular";
    font-size: 14px;
    height: 36px;
  }
  
  #chat .list-chats .input_filter::placeholder {
    font-size: 13px;
    color: #ccc;
  }
  
  #chat .list-chats .input_filter:focus {
    outline: none;
    border: none;
  }
  
  #chat .list-chats .chat {
    padding: 15px;
    border-bottom: solid 1px rgba(204, 204, 204, 0.2);
  }
  
  #chat .list-chats .chat:hover {
    background-color: #f5f5f5;
  }
  
  #chat .list-chats .chat label {
    color: #000;
    font-family: "Montserrat-Regular";
    font-size: 16px;
  }
  
  #chat .list-chats .chat span {
    font-size: 14px;
  }
  
  #chat .card-chat {
    background-color: #ffff;
    width: 100%;
    height: 95vh;
    border: solid 1px rgba(204, 204, 204, 0.2);
  }
  
  #chat .card-chat .header {
    background-color: #f0f0f0;
    height: 9%;
    padding: 15px;
    border-bottom: solid 1px rgba(204, 204, 204, 0.2);
  }
  
  #chat .card-chat .header label {
    color: #000;
    font-family: "Montserrat-Regular";
    font-size: 18px;
  }
  
  #chat .card-chat .body {
    height: 84%;
    background: #fff;
    background-image: url("../../assets/imgs/wallpaper.png");
    background-position: center;
    background-size: cover;
    overflow: auto;
    padding-left: 20px;
    padding-top: 20px;
    padding-right: 20px;
    overscroll-behavior-y: contain;
    scroll-snap-type: y proximity;
  }
  
  #chat .body > div:last-child {
    scroll-snap-align: end;
    padding-bottom: 20px;
  }
  
  #chat .card-chat .body .card-me {
    background-color: #dcf8c6;
    border-radius: 10px 0px 10px 10px;
    padding: 5px 10px;
  }
  
  #chat .card-chat .body .card-he {
    background-color: #ffff;
    border-radius: 0px 10px 10px 10px;
    padding: 5px 10px;
  }

  #chat .card-chat .body .card-waiting {
    background-color: #f5ecba;
    border-radius: 10px 0px 10px 10px;
    padding: 5px 10px;
  }
  
  #chat .card-chat .body .card-resp {
    opacity: 0.9;
    border-radius: 0px 0px 0px 0px;
    padding: 10px 10px;
    border-left: solid 5px #3c9788;
    margin-bottom: 5px;
  }
  
  #chat .card-chat .body .card-file-me {
    background-color: #dcf8c6;
    border-radius: 10px 0px 10px 10px;
    padding: 10px 10px;
  }
  
  #chat .card-chat .body .card-file-he {
    background-color: #ffff;
    border-radius: 0px 10px 10px 10px;
    padding: 10px 10px;
  }

  #chat .card-chat .body .card-file-waiting {
    background-color: #f5ecba;
    border-radius: 10px 0px 10px 10px;
    padding: 10px 10px;
  }
  
  #chat .card-chat .body .card-me label {
    color: #000;
    font-family: "Montserrat-Regular";
    font-size: 14px;
  }
  
  #chat .card-chat .body .card-he label {
    color: #000;
    font-family: "Montserrat-Regular";
    font-size: 14px;
  }
  
  #chat .card-chat .body .card-resp label {
    color: #000;
    font-family: "Montserrat-Regular";
    font-size: 13px;
  }
  
  #chat .card-chat .body .card-me a {
    color: #0275d8;
    font-family: "Montserrat-Regular";
    font-size: 14px;
  }
  
  #chat .card-chat .body .card-he a {
    color: #0275d8;
    font-family: "Montserrat-Regular";
    font-size: 14px;
  }
  
  #chat .card-chat .body .card-resp a {
    color: #0275d8;
    font-family: "Montserrat-Regular";
    font-size: 13px;
  }
  
  #chat .card-chat .body .card-me span {
    font-size: 12px;
  }
  
  #chat .card-chat .body .card-he span {
    font-size: 12px;
  }
  
  #chat .card-chat .body .card-resp span {
    color: #3c9788;
    font-size: 12px;
  }
  
  #chat .card-chat .body .card-file-me span {
    font-size: 12px;
  }
  
  #chat .card-chat .body .card-file-he span {
    font-size: 12px;
  }
  
  #chat .card-chat .footer {
    background-color: #f0f0f0;
    height: 7%;
    padding: 8px;
    border-bottom: solid 1px rgba(204, 204, 204, 0.2);
  }
  
  #chat .card-chat .input_chat {
    background-color: #fff;
    border-radius: 15px;
    border: none;
    width: 100%;
    padding-top: 5px;
    padding-left: 15px;
    font-family: "Montserrat-Regular";
    font-size: 14px;
    height: 40px;
  }
  
  .w-10 {
    width: 10% !important;
  }

  #chat .card-chat .input_chat::placeholder {
    font-size: 13px;
    color: #999;
  }
  
  #chat .card-chat .input_chat:focus {
    outline: none;
    border: none;
  }
  
  #chat .btn-main {
    background-color: #3c9788;
  }
  
  /* SCROLL */
  
  #chat ::-webkit-scrollbar {
    width: 6px;
  }
  
  #chat ::-webkit-scrollbar-thumb {
    background: #3c9788;
    border-radius: 3px;
  }
  
  #chat ::-webkit-scrollbar-track {
    background: #b9b3b3;
  }
  
  #chat .ajust-space-chat {
    padding-left: 0px;
    margin-left: 0px;
  }
  
  #chat .ajust-space-list {
    padding-right: 0px;
    margin-right: 0px;
  }
  
  #chat .list-conversas-button {
    color: #000;
    font-size: 16px;
    background-color: #f0f0f0;
    padding: 5px 15px;
    border-radius: 2px;
  }

  #chat .labeltext {
    font-size: 13px !important;
  }

  #chat .justify-control {
    width: 100%;
    display: flex;
    justify-content: end;
  }
  
  @media (max-width: 768px) {
    #chat .list-chats .filter {
      height: 15%;
    }
    #chat .card-chat .header {
      height: 10%;
    }
  
    #chat .card-chat .footer {
      height: 10%;
    }
  
    #chat .card-chat .body {
      height: 80%;
    }
  
    #chat .ajust-space-chat {
      padding-left: 15px;
      margin-left: 0px;
    }
  
    #chat .ajust-space-list {
      padding-right: 15px;
      margin-right: 0px;
    }
  }
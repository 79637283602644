#obras .arrasteAqui {
    border: 1px dashed #1544bd;
}

#obras .arrasteAqui p {
    color: #1544bd;
}

#obras .list {
    background: #f7f7fa;
    border-radius: 8px 8px 0px 0px;
    background-color: var(--cor-theme);
    padding: 20px;
    height: 450px;
    overflow: auto;
  }
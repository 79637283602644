body {
  --cor-principal: #2E8B57;
  --cor-principal-escura: #2f4f4f;
  --cor-principal-baixaopacity: #2f4f4f60;
  --cor-principal-minopacity: #2f4f4f21;
  --cor-destaque-letra: #383e4d;

  --cor-theme: #f7f7fa !important;
  --cor-theme-cards: #fff !important;
  --cor-theme-cards-secundary: #f7f7fa;
  --cor-sombreado: rgba(118, 130, 130, 0.25);
  background-color: var(--cor-theme);
  min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
p,
span,
label,
a {
  color: var(--cor-destaque-letra);
}

span {
  color: #b3b3b3;
}

h1,
h2,
h3,
h4,
h5,
p {
  text-align: start;
}

h1,
h2,
h3 {
  font-family: "Montserrat-SemiBold";
}

h4,
h5,
p,
small,
span,
label,
a,
.action {
  font-family: "Lato-Regular";
}

label,
a,
.action {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}

.action {
  color: var(--cor-principal-escura);
  cursor: pointer;
}

.action:hover {
  text-decoration: underline;
}

.actionRemoveAll {
  color: var(--cor-principal-escura);
  cursor: pointer;
  font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
p,
span,
label,
th {
  cursor: default;
}

@media (max-width: 768px) {
  p,
  a,
  label,
  span {
    font-size: 14px;
  }
}

label {
  white-space: pre-line;
}

p {
  white-space: pre-line;
}

.area_body {
  padding-left: 45px;
  padding-right: 45px;
}

@media (max-width: 1100px) {
  .area_body {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

/* INPUTS, TEXTAREA */

.input-form,
.input-search,
.input-search-white {
  height: 46px;
}

input:disabled {
  background-color: #fff;
}

.textarea-form,
.input-form,
.input-search,
.input-search-white {
  background: #fff;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: "Lato-Regular";
  width: 100%;
  padding: 15px;
}

.inputPadrao {
  font-family: "Lato-Regular";
  background: #f7f7fa;
  border-radius: 4px;
  width: 100%;
  border: none;
  height: 46px;
  padding: 15px;
}

.select-search {
  background: #f7f7fa;
  font-family: "Lato-Regular";
  color: var(--cor-principal-escura);
  width: 100%;
}

.input-search-white,
.select-search,
.select-search:focus {
  border: none;
  outline: none;
}

.select-find-lead {
  background: #f7f7fa;
  font-family: "Lato-Regular";
  color: var(--cor-principal-escura);
  width: 100%;
  height: 46px;
  padding-left: 15px;
  border: 1px;
}

.select-find-lead,
.select-find-lead:focus {
  border: none;
  outline: none;
}

.textarea-form::placeholder,
.input-form::placeholder,
.input-search::placeholder,
.input-search-white::placeholder,
.inputPadrao::placeholder {
  font-weight: bold;
  color: rgba(110, 116, 135, 0.5);
}

.input-search:focus,
.textarea-form:focus,
.input-form:focus,
.input-search-white:focus,
.inputPadrao:focus {
  border-color: var(--cor-principal-baixaopacity);
  box-shadow: 0 0 0 0.1rem var(--cor-principal);
  outline: none;
}

.css-yk16xz-control:active,
.css-1pahdxg-control:active,
.css-1pahdxg-control:hover,
.css-yk16xz-control {
  border: 1px solid var(--cor-principal) !important;
}

.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  .customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}

.click-icon {
  cursor: pointer;
}

@media (max-width: 845px) and (min-width: 768px) {
  .customDatePickerWidth {
    padding-right: 0px;
  }
}

/* BOTOES */

.btn-main,
.btn-primary,
.btn-red,
.btn-secondary {
  border-radius: 4px;
  padding: 10px 20px;
  border: none;
  min-height: 46px;
  font-family: "Lato-Regular";
  color: #fff;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.btn-secondary {
  background: var(--cor-theme);
  color: #383e4d !important;
}

.btn-red {
  background: #d9534f;
  color: #fff !important;
}

.btn-secondary:hover {
  color: var(--cor-theme) !important;
}

.btn-main {
  background: var(--cor-principal-escura);
}

.btn-primary,
.btn-main:hover {
  background: var(--cor-principal) !important;
}


.btn-main:focus,
.btn-secondary:focus,
.btn-red:focus,
.btn-primary:hover,
.btn-primary:active {
  outline: none;
  border: none;
}

.btn-primary:hover {
  background: var(--cor-principal-escura);
}

.btn-primary:active {
  background: var(--cor-principal-escura) !important;
  box-shadow: 0 0 0 0.2rem var(--cor-principal-escura);
}

/* REACT VIDEO */

.h-full {
  height: 100%;
}

.react-audio-player:focus,
video:focus,
button.darkmode-toggle:focus {
  outline: none;
  border: none;
}

/* LOADING */

.loading {
  position: relative;
  -webkit-filter: blur(15px);
  -moz-filter: blur(15px);
  -o-filter: blur(15px);
  -ms-filter: blur(15px);
  filter: blur(15px);
}

/* SCROLL */

::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--cor-principal-escura);
  border-radius: 3px;
}

::-webkit-scrollbar-track {
  background: #b9b3b3;
}

/* PAGINATION */

.page-link {
  border: none;
  color: #525759;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  border-radius: 0%;
  background-color: var(--cor-principal-escura) !important;
  border-color: #000000;
}

/* NAO SELECIONAVEL */

.naoSelecionavel,
.page-link,
.page-item,
.react-tagsinput,
.react-tagsinput-tag {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.hiddenOverflow {
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
}

@media (min-width: 440px) {
  .pagination {
    justify-content: center;
  }
}

.page-link {
  padding: 0.4rem 0.6rem;
}

.positionSticky {
  position: fixed;
}

/* TABELA PADRAO */

th {
  border-top: none !important;
  border-bottom: none !important;
  font-family: "Lato-Regular";
  color: rgba(110, 116, 135, 0.6);
}

td {
  border-top: none !important;
  border-bottom: 1px solid rgba(167, 167, 167, 0.13) !important;
}

tr:hover {
  background-color: var(--cor-principal-minopacity) !important;
}

.pointerTable {
  font-family: "Lato-Regular";
  color: #5e5858;
  cursor: default;
}

.detalhes {
  font-family: "Lato-Regular";
  color: var(--cor-principal);
  cursor: pointer;
}

.danger {
  font-family: "Lato-Regular";
  color: #d9534f;
  cursor: pointer;
}

/* CARDS PADROES */

.card-content {
  background-color: #ffffff;
  padding: 30px 30px;
  border-radius: 8px;
}

.card-options {
  background-color: #f7f7fa;
  padding: 15px 20px;
  border-radius: 4px;
  align-items: center;
  cursor: pointer;
}

.qrcode {
  padding: 1px;
  background-color: white;
}

#nprogress .bar {
  background: var(--cor-principal);
  height: 6px;
}

/* UPLOAD ARQUIVO */

.arrasteAqui {
  border: 1px dashed #80808046;
  cursor: pointer;
  border-radius: 2%;
  overflow: auto;
}

.sucess-file {
  border: 1px dashed #5cb85c;
}

.bordernone:focus {
  outline: none;
}

.arrasteAqui p {
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  color: #808080;
}

.sucess-file p {
  color: #5cb85c;
}

.modal-qrcode {
  background-color: #ffffff !important;
}
.list-tabs {
    display: flex;
    flex-direction: row;
    text-align: center;
}

.list-tabs .tab {
    cursor: pointer;
    font-family: "Lato-Bold";
    font-size: 16px;
    color: #384347;
    padding: 10px;
    margin: 2px;
    width: 200px;
}

.list-tabs .active {
    border-bottom: 3px solid #047EAD;
}

@media(max-width: 500px) {
    .list-tabs .tab {
        width: 100%;
        font-size: 15px;
        padding: 5px;
    }
}
.area_top {
  padding: 25px 35px 15px 35px;
}

@media (max-width: 767px) {
  .area_top {
    padding: 5px;
    padding-top: 15px;
  }
}

.help {
  background-color: rgba(21, 68, 189, 0.1);
  border-radius: 25px;
  padding: 10px;
  padding-bottom: 15px;
  width: 100px;
  cursor: help !important;
  -webkit-touch-callout: none; /* iPhone OS, Safari */
  -webkit-user-select: none; /* Chrome, Safari 3 */
  -khtml-user-select: none; /* Safari 2 */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  user-select: none; /* Possível implementação no futuro */
}

.help span {
  color: #000;
  cursor: help !important;
}

.help .helpIcon {
  color: var(--cor-principal-escura);
  position: relative;
  top: 5px;
  margin-right: 10px;
  cursor: help !important;
}

.underline:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* HELPER */

.react-joyride__tooltip span {
  color: #ffffff !important;
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top:20vh;
  }

  #register.login-container{
      top:15vh
  }
  
  .login-container form {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
  }
  
  .login-container form button {
    margin-top: 15px;
    border: 0;
    border-radius: 4px;
    height: 48px;
    font-size: 16px;
    background: #292b2c;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
  }

  .login-container  .inputPadrao {
    font-family: "Lato-Regular";
    background: #e4e4e981;
    border-radius: 4px;
    width: 100%;
    border: none;
    height: 46px;
    padding: 15px;
  }
  
   .login-container .inputPadrao::placeholder {
    font-family: "Lato-Regular";
    font-style: normal;
    font-weight: bold;
    color: rgba(110, 116, 135,0.5);
  }
  
   .login-container .inputPadrao:focus {
    border-color: #1544BD;
    box-shadow: 0 0 0 0.2rem #3f71f06b;
    outline: none;
  }

  .login-container .labelSmall{
      text-align: center;
      color:#1544BD;
  }

.authDisplayLogoBranca{
  display:none;
  height: 250px;
}

.authDisplayLogoPreta{
  display: inline;
  height: 250px;
}
#modal-contatos .css-yk16xz-control,
.css-26l3qy-menu,
.css-1pahdxg-control {
  border: 1px solid #e1e1e1 !important;
  box-shadow: 0 0 0 1px #e1e1e1 !important;
}

#modal-contatos .css-1pahdxg-control {
  border: 1px solid #e1e1e1 !important;
  box-shadow: 0 0 0 2px #e1e1e1 !important;
}

#modal-contatos .css-yk16xz-control:active,
.css-1pahdxg-control:active,
.css-1pahdxg-control:hover {
  border: 1px solid #e1e1e1 !important;
}

#modal-contatos .css-6q0nyr-Svg {
  fill: #e1e1e1 !important;
}

.css-yk16xz-control,
.css-1pahdxg-control {
  min-height: 46px !important;
}

.avatar {
  border-radius: 50px !important;
}

.AiOutlineCloudDownload {
  top: 4px;
}
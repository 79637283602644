#config span.adicionarInput {
  color: var(--cor-principal-escura);
  cursor: pointer;
}

#config .editTable {
  color: #1544BD;
  cursor: pointer;
  font-family: "Lato-Regular";
}

#config .removeTable {
  color:#ca0808 ;
  cursor: pointer;
  font-family: "Lato-Regular";
}

#config .btn-danger{
  border-radius: 4px;
  padding: 10px 20px;
  border: none;
  min-height: 46px;
  font-family: "Lato-Bold";
  color: #fff;
  cursor: pointer;
}

#config h4{
  color: #232d4588;
  font-weight: bold;
}

/* REACT TAGS */

#config .react-tagsinput {
  background: #f7f7fa;
  border: 1px solid #ccc;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
  cursor:text;
}

#config .react-tagsinput--focused {
  border-color: var(--cor-principal-baixaopacity) !important;
  box-shadow: 0 0 0 0.2rem var(--cor-principal-baixaopacity) !important;
}

#config .react-tagsinput-tag {
  background-color: var(--cor-principal-minopacity) !important;
  border-radius: 5px !important;
  border: 1px solid var(--cor-principal) !important;
  color: var(--cor-principal-escura);
  font-family: "Lato-Regular" !important;
}

#config .react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

#config .react-tagsinput-tag a::before {
  position: relative;
  padding-left: 4px;
  top: 1px;
}

#config .react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: "Lato-Bold" !important;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 88px !important;
}

#config .input-group-text{
  border-radius: 0px 4px 4px 0px;
  border: none;
  background-color: #E9EAF4;
}

#config .input-group-append{
  position: relative;
  top: -46px;
  height: 46px;
  border-radius: 4px;
  border: none;
  background-color: #f7f7fa;
}

#config .checkGreen{
  color: green;
}

pre {
  display: block;
  overflow-x: auto;
  background: #19171c;
  color: #8b8792;
  padding: 0.5em;
}